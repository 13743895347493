import { graphql } from 'gatsby'
import { useQuery, gql } from '@apollo/client'
import { Spinner } from '@dfds-ui/react-components'
import { Text } from '@dfds-ui/typography'
import { useTranslation } from 'react-i18next'

import { PageLayout } from '../components/Layout'
import Hero from '../components/Hero/Hero'
import { useLocaleContext } from '../components/LocaleContext'
import AccordionList from '../components/PageContentAccordionList/PageContentAccordionList'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'

const FEEDQUERY = gql`
  query FeedQuery($id: String!, $locale: String!, $preview: Boolean = false) {
    liveFeedPage(id: $id, locale: $locale, preview: $preview) {
      __typename
      contentReferencesCollection(limit: 20) {
        items {
          __typename
          ... on TextAndMediaContent {
            title
            text
            videoId
            mediaType
            linksCollection {
              items {
                __typename
                ... on Link {
                  __typename
                  sys {
                    id
                  }
                  linkText
                  callToAction
                  description
                  destination {
                    ... on UrlSlug {
                      slug
                      disableIndexing
                      theme
                    }
                  }
                }
                ... on AssetLink {
                  asset {
                    sys {
                      id
                    }
                    title
                    description
                    contentType
                    url
                    width
                    height
                  }
                  linkText
                  presentation
                }
              }
            }
            imagesCollection(limit: 1) {
              items {
                url
              }
            }
          }
          ... on TextContent {
            title
            text
            linksCollection {
              items {
                __typename
                ... on Link {
                  __typename
                  sys {
                    id
                  }
                  linkText
                  callToAction
                  description
                  destination {
                    ... on UrlSlug {
                      slug
                      disableIndexing
                      theme
                    }
                  }
                }
                ... on AssetLink {
                  asset {
                    sys {
                      id
                    }
                    title
                    description
                    contentType
                    url
                    width
                    height
                  }
                  linkText
                  presentation
                }
              }
            }
          }
        }
      }
    }
  }
`

const LiveFeedPage = ({ data: { content } }) => {
  const { t } = useTranslation()
  const { locale } = useLocaleContext()
  const {
    hero,
    covidBannerLayout,
    sys: { id },
    accordionArchiveReferencesCollection: { items: accordions },
    faqSection,
  } = content.page

  const { loading, error, data } = useQuery(FEEDQUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: id || null,
      locale: locale || 'en',
    },
  })

  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} />
          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      {loading && <Spinner />}
      {error && <Text styledAs={'sectionHeadline'}>{t('ERROR_MESSAGE')}</Text>}
      {data &&
        data.liveFeedPage.contentReferencesCollection.items.map((item, i) => {
          return (
            <TextAndMediaLayout
              key={i}
              reverse
              mediaWidth={{ md: 5 }}
              {...item}
            />
          )
        })}
      {accordions &&
        accordions.map((accordion, i) => {
          return <AccordionList {...accordion} key={i} />
        })}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
    </PageLayout>
  )
}

export const query = graphql`
  query LiveFeedPage(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: liveFeedPage(id: $id, locale: $locale, preview: $preview) {
        __typename
        sys {
          id
        }
        hero: heroReference(locale: $locale) {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        accordionArchiveReferencesCollection(limit: 10) {
          items {
            sys {
              id
            }
            title
            itemsCollection(limit: 5) {
              items {
                ... on contentful_AccordionListItem {
                  __typename
                  sys {
                    id
                  }
                  title
                  content {
                    ... on contentful_PageContentTextSection {
                      __typename
                      sys {
                        id
                      }
                      showTitle
                      blockWidth
                      backgroundColor
                      contentRef {
                        __typename
                        text
                        title
                        linksCollection(limit: 5) {
                          items {
                            ...ItemLink
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default LiveFeedPage
